/* Nunito Font */

:root { font-family: 'Nunito', sans-serif; }
@supports (font-variation-settings: normal) {
  :root { font-family: 'Nunito var', sans-serif; }
}

:root {
  --font-color: #0F1177;
  --secondary-font: #4f5f71;
  --background: #E4F2F6;
  --primary-color: #0F1177;
  --secondary-color: #0379A0;
}

@font-face {
  font-family: 'Nunito';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("./asssets/fonts/Nunito/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Nunito';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("./asssets/fonts/Nunito/Nunito-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Nunito';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("./asssets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Nunito';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("./asssets/fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
}

/* LexendDeca Font */

@font-face {
  font-family: 'LexendDeca';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./asssets/fonts/LexendDeca/LexendDeca-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'LexendDeca';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./asssets/fonts/LexendDeca/LexendDeca-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'LexendDeca';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("./asssets/fonts/LexendDeca/LexendDeca-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'LexendDeca';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("./asssets/fonts/LexendDeca/LexendDeca-SemiBold.ttf") format('truetype');

}

@font-face {
  font-family: 'LexendDeca';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./asssets/fonts/LexendDeca/LexendDeca-Bold.ttf") format('truetype');

}


body {
  margin: 0;
  font-family:'Nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var( --background);
}

.app-container{
  display: inline-flex;
  max-height: 100vh;
  max-width: inherit;
  width: 100%;
  background-color: var( --background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backdrop{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000023;
  z-index: 1;
}

.notif-modal{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  overflow: hidden;
}

.modal__content{
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  /*position: relative; */
  max-height: 1150px;
  height: 100%;
  width: 91%;
}

.modal-header-positioning{
  position: absolute;
  top: 11vh;
  right: 1vw;
}

.modal,
.invite-modal,
.delete-modal{
  position: absolute;
  z-index: 1000;
}

.open-jobs-popup,
.keyword-submit-popup{
  padding: 15px !important;
  font-family: 'LexendDeca';
  font-size: 1rem;
  border-radius: 15px !important;
}

@keyframes fadeIn {
  from { 
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-display-modal{
  animation: fadeIn 3s;
}

.keyword-submit-popup>div>p{
  margin-top: 10px;
}

.open-jobs-popup>div>p{
  margin-top: -50px;
}

.invite-modal{
  top: calc(50% - 140px);
  left: calc(50% - 125px);
}

.confirm-delete-modal{
  top: calc(50% - 140px);
  left: calc(50% - 125px);
}

.help-modal {
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0 10px 20px rgba(black, 0.2);
  left: 50%;
  pointer-events: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30em;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.help-modal-header {
  display: flex;
}

.help-modal-title {
    color: var(--font-color);
    padding: 1.5em 2em;
    pointer-events: all;
    position: relative;
    width: 100%;
}

.help-modal-close {
  align-items: center;
  color: #111827;
  display: flex;
  height: 4.5em;
  justify-content: center;
  position: absolute;
  right: -15px;
  pointer-events: all;
  top: -10px;
  width: 4.5em;
  cursor: pointer;
}



.help-modal-title > h4 {
      font-size: 1.15rem;
      text-align: center;
      font-weight: 600;
      line-height: normal;
      font-family: 'LexendDeca';
    }


.help-modal-content {
      border-top: 1px solid #e0e0e0;
      padding: 2em;
      pointer-events: all;
      overflow: auto;
      text-align: center;
}

.help-modal-content > a {
  text-decoration: none;
  color: var( --font-color);
  font-weight: bold;
}

.help-download {
  padding:0px;
  padding-top: 15px;
}


.delete-modal{
  top: calc(50% - 60px);
  left: calc(50% - 100px);
}

.emp-delete-modal{
  top: calc(50% - 200px);
  left: calc(50% - 330px);
}

.job-modal{
  top: calc(50% - 175px);
  left: calc(50% - 100px);
}

p,
h1,
h2,
h3,
h4,
h5,h6 {
  margin:0px;
}

* {
  box-sizing: border-box
}

#root {
  display: flex;
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  /* height: 100vh;  CONTAINER HOLDER*/
}

@media( min-width: 992px) {
 #root {
  height: 100vh;
 }
}



/* div[id^='react-select-']{
  z-index: 1;
} */

/* Google Search box */
.pac-container{
  border: 2px solid var( --font-color);
  background: var( --background);
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.034);
  border-radius: 10px;
  z-index: 1000;
}

.pac-container.pac-logo::after{
  opacity: 0.12;
}

.pac-item {
  color:#4f5f71;
}

.pac-matched{
  color: var( --font-color);
}

/* Global styles */

.notif-highlight{
  background: white !important;
  color: var( --font-color) !important;
}

.highlight {
  background: lightgrey;
}

.inline {
  display: inline-flex;
}

.inline-c{
  display: inline-flex;
  justify-content: center;
}

.inline-a{
  display: flex;
  align-items: center;
}

.inline-btwn{
  display: inline-flex;
  justify-content: space-between;
}



.inline-end{
  display: inline-flex;
  align-items: flex-end;
}

.inline-around{
  display: inline-flex;
  justify-content: space-around;
}

.grid{
  display:grid;
}

.flex-start{
  align-items: flex-start;
}

.full-width{
  width: 100% !important;
}

.child-half-width { 
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}

.child-half-width > *{
  width: 50%;
}

.inline-ca{
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.link-text{
  color: white;
  font-weight:500;
  text-decoration: none;
}


.go-back {
  min-width: 145px;
  align-items: center;
  cursor: pointer;
}

.go-back>img{
  width:20px;
}

.go-back>p{
  font-size:0.7em;
  font-weight: 600;
  padding: 0 5px;
  text-align: left;

}

.nav-btn{
  width: 100%;
  cursor: pointer;
}

.go-back:hover,
.nav-btn:hover{
  transform: scale(1.03);
}

/* development helpers */


/* Card stylings */
.card,
.card-settings {
  background: white;
  color: 'black';
  border-radius: 25px;
  border: 2px solid var( --background);
}


.card-settings{
  height: 80vh;
}

/* TopWidgetComponent Card */

.dash-top-level-cards {
  font-family: 'LexendDeca';
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: normal;
  text-align: left;
  height: 104px;
  padding: 16px 24px 11px;
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  width:49.5%;
  background: var( --secondary-color);
  border-color: var( --secondary-color);
}


.dash-top-level-cards > p {
  color: white;
}

div.dash-top-level-cards > div > h1 {
  font-size: 1em;
}

div.dash-top-level-cards > div > img {
  width: 64px;
  padding: 5px;
  border-radius: 12px;
  background-color: white;
}

/* WidgetComponent Card */

/* .dash-col1 > div > div > h1, .dash-col2 > div > div > h1  {
  text-transform: uppercase;
  font-size: 1.00em;
  font-family: LexendDeca;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
} */


.dash-main-level-cards{
  width: 99%;
  padding: 1.15em;
}

.dash-main-level-cards> h1{
  margin: 0.4rem 0.2rem;
}

.mid-widget-cont {
  align-items: center;
  padding-top: 1.15em;
  width: 100%;
}

.latest-name {
  flex-grow: 1;
  word-break: normal;
}

.dash-col1 > div > div > a, .dash-col2 > div > div > a {
  text-decoration: none;
  font-weight: 600;
  line-height: 1.85;
  text-align: left;
  color: #4f5f71;
  font-size: 0.95em;
  display: inline-flex;
}

#dash-widget-icon-2 {
  vertical-align: text-top;
  padding-right: 0.3em;
}

.dash-col1 > div > a > div > p {
  color: black;
  font-weight: 600;
  font-size: 1.10em;
  min-width: 200px;
}

div.dash-main.inline > div.dash-col1 > div, div.dash-main.inline > div.dash-col2 > div {
  overflow: auto;
}

.dash-col2 > div > a > div > p {
  color: black;
  font-weight: 600;
  font-size: 1.20em;
  align-self: center;
}

.dash-col1 > div > a, .dash-col2 > div > a {
  text-decoration: none;
  
}

.dash-col1 #job-badge {
}

#dash-time-stamp {
  font-family: Nunito;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.33;
  color: #4f5f71;
}


.employment-card{
  min-height: 0px;
  padding: 20px;
  border-radius: 15px;
  margin: 20px 5px;
  border: none;
}

.employment-record,
.employment-record-item{
  margin: 10px 0px;
}

.employment-record-item{
  border: 2px dashed #0000004d;
  border-style: none none dashed none;
}

.employment-record-header{
  display: flex;
  align-items: center;
}

.loading-container{
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}


.animation-overlay{
  width: 100%;
  height: calc(90%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.animation-overlay>img{
  width: fit-content;
  height: auto;
  padding-bottom: 100px;
}

/* Form global styles */
.form-error-message {
  width: 100%;
  margin-top: 3px;
  margin-left: 10px;
  font-size: 0.8rem;
  color: #e31c3d;
  font-weight: 500;
  font-style: italic;
  height: fit-content;
}

/* Employer Detailed View (EmployerComponent) styles */
div.company-details-container > div.inline-ca > h1 {
  font-size: 1.5em;
}

div.company-details-container > div.inline-ca > img {
  max-width: 80px;
  margin-right: 1em;
  margin-left: 1em;
}

div.emp-support-lead > h5 {
  font-family: 'LexendDeca';
  font-weight: 600;
  line-height: 1.53;
  text-align: left;
}

.provider-details {
  font-family: Nunito;
  line-height: 1.33;
  text-align: left;
  color: #4f5f71;
  font-weight: 800 !important;
  font-size: 0.8em;
}

div.emp-support-lead > div > div > p.provider-name {
  font-weight: 600;
}

div.emp-support-lead > div > img, .es-img-info > img {
  max-width: 47.6px;
  max-height: 47.6px;
  width: 47.6px;
  height: 47.6px;
  margin-right: 1em;
  border-radius: 25px;
}


div.employer-column1 > div.emp-overview {
  margin-top: 1em;
}

div.employer-column1 > div.emp-overview > div > img {
  background-color: var(--background);
  padding: 0.8em;
  width: 47.6px;
  height: 47.6px;
  max-width: 47.6px;
  min-width: 45px;
  max-height: 47.6px;
  border-radius: 8px;
  margin-right: 1em;
  border: 1px solid var(--primary-color);
}

div.employer-column1 > div.emp-overview > div > div {
  align-self: center;
}

div.emp-overview > div {
  margin-bottom: 1em;
}

div.emp-overview > h2, 
div.employer-column2 > h5 {
  font-family: 'LexendDeca';
  font-weight: 600;
  text-align: left;
  color: var(--font-color);
  font-size: 0.95em;
  margin-bottom: 1em;
}

div.emp-overview > div > div > h5 {
  font-family: 'LexendDeca';
  font-weight: 600;
  text-align: left;
  color: var(--font-color);
  font-size: 0.83em;
}

div.emp-overview > div > div > p {
  font-family: 'Nunito';
  font-weight: 600;
  color: black;
  font-size: 0.83em;
  margin: 1px 1px;
}

p.keyword-tags, 
.details-tag {
  border-radius: 8px;
  background-color: var(--background);
  padding: 0.2em 0.5em;
  font-weight: 600;
  line-height: 1.33;
  color: var( --font-color)!important;
  text-align: left;
  display: inline-grid!important;
  text-transform: lowercase;
  margin: 1px 2px;
}

div.employer-column2 > section {
  margin-bottom: 3em;
}

div.employer-column2 > section > p {
  font-family: 'Nunito';
  font-weight: 500;
  line-height: 1.71;
  color: #050505;
  text-align: left;

}

.recommendations-section {
  max-width: 1900px;
  height: 18vh;
  max-height: 150px;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

div.employer-reco-info > h4 {
  width: 70%;
  font-family: 'LexendDeca';
  font-weight: 600;
  text-align: left;
  color: var(--font-color);
  line-height: 1.5;

}

.detailed-note {
  align-self: center;
}

.note-content {
  font-size: 1em;
}

.note-logo {
  min-height: 1.5em;
  max-height: 3em;
  padding-right: 0.5em;
}

.note-meta {
  color: #4f5f71;
  font-size: 0.7em;
  font-weight: 600;
  margin-top: 0.25em;
}

.individual-notes {
  margin-top: 1.5em;
}

.note-parent {
  padding: 10px !important;
  margin-bottom: 0.5em !important;
  justify-content: flex-start !important;
}

.comments-wrapper{
  width: 99%;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
}

div.employer-reco-info > img {
  background-color: var( --secondary-color);
  padding: 1em;
  width: 60px;
  height: 60px;
  max-width: 60px;
  max-height: 60px;
  border-radius: 16px;
}

.employer-content, 
.employer-nav-btns, 
.individual-content {
  border: 2px solid var(--background);
  box-shadow: 1px 4px 3px 1px var(--background);
}

.employer-nav-btns {
height: inherit;
width: 31%;
}

@media( max-width: 768px) {
  .employer-nav-btns {
    width: 100%;
    border-radius: 16px;
    border: solid 1.5px #d9e1e7;
    box-shadow: none;
  }

  .employer-nav-btns:not(:last-child) {
    margin-bottom: 12px;
  }
}

/* Individual Detailed View (IndividualComponent) styles */

.iet-header > img {
  margin-right: 1em;
}

.iet-headertext {
  align-self: center;
}

.iet-header > h5 {
  align-self: center;
}

.iet-headertext > h6 {
  color: #4f5f71;
  margin-top: 0.25em;
} 

.iet-header {
  margin: 1.5em 0;
  color: var(--font-color);
}

.form-icon {
  align-items: center;
  margin: 1em;
}

.keywords-array {
  display: flex;
  width: 100%;
  flex-flow: wrap;
  justify-content: flex-start;
}


.indv-details-card {
  display: inline-flex;
  width: 100%;
  margin-left: 2em;
}

.title-and-status {
  align-self: center;
}

.title-and-status > h1 {
  font-family: 'LexendDeca';
  font-weight: 600;
  line-height: 1.14;
  color: #050505;
}

.hour-badge,
.job-type-badge {
  padding: 0.3em 1em;
  border-radius: 5px;
  background-color: #4f5f71;
  text-transform: capitalize;
  color: white;
  margin-right: 1em;
  font-size: 0.65em;
  width: fit-content;
}

.status-badge {
  padding: 0.3em 1em;
  border-radius: 5px;
  background-color: #3d7652;
  text-transform: capitalize;
  color: white;
  font-size: 0.65em;
  margin-right: 1em;
  height: fit-content;
}

.puzzle-loader-gif{
  border-radius: 100px;
}

.check-gif{
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  max-height: 200px;
}

/* Recommendation Component Styles */

.recomm-column1 > h4 {
  font-family: 'LexendDeca';
  line-height: 1.53;
  color: #050505;
  font-weight: 600;
  font-size: 0.95em;
  padding: 10px;
}

.recomm-desc-cont > h6 {
  color: #4f5f71;
  line-height: 1.36;
}

.distance-badge {
    border-radius: 8px;
    background-color: var( --font-color);
    padding: 0.3em 1em;
    font-weight: 600;
    color: white;
    font-size: 0.75em;
    text-align: center;
}

div.distance-badge > p{
  width: max-content;
  overflow: hidden;
  min-width: 90px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.matches-widget > h5 {
  font-family: 'LexendDeca';
  line-height: 1.53;
  color: #050505;
  font-weight: 600;
  font-size: 0.95em;
  text-transform: uppercase;
}

.reco-tags {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5em 0.4em;
}

.reco-tags > h3 {
  width: min-content;
  margin-left: 0.5em;
}

.text-overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-badge {
  height: 30px;
  padding: 0.5em 1em;
  border-radius: 5px;
  background-color: var( --font-color);
  color: white;
  font-size: 0.75em;
  margin: 0 0.2rem;
  min-width: fit-content;
  text-align: center;
  overflow: hidden;
}

.header-badge{
  min-width: 110px;
}

.badge-title,
.skills-badge, 
.interests-badge, 
.restrictions-badge {
  max-width: 110px;
  height: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* padding: 0.4em 1em; */
  font-size: 0.75em;
  font-family: Nunito;
  font-weight: 600;
  color: #ffffff;
  text-transform: lowercase;
  margin: 2px 0px;
  z-index: 1;
  white-space: pre-wrap;
  text-align: center;
  padding: 5px 2px;
  margin: 0.1rem 0.2rem;
}

.skills-badge {
  background-color:  #3d7652;
}

.interests-badge {
  background-color: var(--font-color);
}

.restrictions-badge {
  background-color: #eb2c2c;
}
.skills-abs-pos {
  top:0%;
  left:0%;
}

.interests-abs-pos {
  top:0%;
  left:22%;
}

.restrictions-abs-pos {
  top:0%;
  left:44%;
}

.reco-keyword-detail {
  padding-bottom: 0.5em;
}

.badge-type {
  line-height: 1.53;
  text-transform: capitalize;
}

/* .recomm-col2-details > div > div > img {
  background-color: var(--background);
  border: 1px solid var(--primary-color);
  padding: 0.8em;
  width: 47.6px;
  height: 47.6px;
  max-width: 47.6px;
  max-height: 47.6px;
  border-radius: 8px;
  margin-right: 1em;
} */

.content {
  align-self: center;
}

.content > h4 {
  font-family: 'LexendDeca';
  font-weight: 600;
  text-align: left;
  color: var(--font-color);
  font-size: 0.83em;
}

.content > p {
  font-family: 'Nunito';
  font-weight: 600;
  color: #4f5f71;
  font-size: 0.83em;
}

.recomm-col2-details > h5 {
  margin-top: 1em;
  margin-bottom: 0.75em;
}

.badge-header{
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

.skills-count{
  padding: 0px;
  margin: 0px;
  font-weight: 600;
  font-family: 'LexendDeca';
  margin-right: 5px;
  justify-content: center;
  align-items: inherit;
  display: inline-flex;
  font-size: 0.8em;
}

.sc-text{
  font-size: 0.85rem;
  /* color: #0F117730 !important;
  font-weight: 500; */
  text-transform: initial !important;
}

.pulse-animate{
  transform: scale(1);
	animation: pulse 2s infinite;
}
/* Responsive styling */

@media (max-resolution: 1dppx) {
  .dash-main {
    height: 70vh;
  }

  .header-component{
    padding-bottom: 0.5em;
  }
}

@media (min-height: 938px) {
  .landing-page-component {
    padding-left: 250px;
    padding-right: 250px;
  }

  .dash-col1 > div > div > h1, 
  .dash-col2 > div > div > h1, 
  div.dash-top-level-cards > div > h1 {
    font-size: 1.15em;
  }

  .active-nav > div > p, .inactive-nav > div > p {
    font-size: 1.15em;
  }

  #root > main > section > header > div:nth-child(1) > h1 {
    font-size: 2em;
  }

  .dash-col1 > div > div > a, 
  .dash-col2 > div > div > a {
    font-size: 1.10em;
  }

  .dash-col1 > div > a > div > p#job-badge {
    font-size: 0.95em;
  }

  .distance-badge {
    font-size: 1em;
  }

  .employer-reco-info {
    min-height: 145px;
    width: 26vw;
  }

  .parent-component{
    max-width: 93vw;
  }
    
  .landing-content-left > h1 {
    font-size: 2.6rem;
  }

  .landing-content-left > h3 {
    font-size: 2.4rem;
  }

  .landing-content-left > p {
    font-size: 1.2em;
  }

  div.employer-reco-info > h4 {
    font-size: 1.00em;
  }

  .note-meta {
    font-size: 0.8em;
  }
  
  .dash-main {
    height: 71vh;
  }

  .company-details-container {
    width: 37vw;
    max-width: 37vw;
    height: 15vh;
    max-height: 15vh;
  }
  
  div.company-details-container > div.inline-ca > h1 {
    font-size: 1.75em;
  }
  
  div.emp-support-lead > h5 {
    font-size: 1.00em;
  }
  
  div.emp-support-lead > div > div {
    font-size: 1em;  
  }

  div.employer-column1 > div.emp-overview > h2, 
  div.employer-column2 > h5 {
    font-size: 1.00em;
  }

  .emp-info > h5 {
    font-size: 0.9em;
  }

  .hour-badge {
    border-radius: 5px;
  }

  .status-badge {
    border-radius: 5px;
  }
  /* .table-header > div > h2 {
    font-size: 1.2em;
  } */

  /* .table-header > div > h2 > span {
    font-size: 0.8em;
  } */

  .table-header > button {
    font-size: 1.00em;
  }

  .employer-column1,
  .recomm-column1 {
    width: 30%;
  }

  .employer-column2 {
    width: 70%;
  }

  .recomm-col2-details{
    height: 75%;
  }

  .es-details-cont {
    margin-top: 0.15em;
  }

  .matches-widget {
    margin-bottom: 0.25em;
  }

}


/* Animations */

@keyframes pulse {
	0% {
		transform: scale(0.98);
		box-shadow: 0 0 0 0 #0571b7;
	}

	50% {
		transform: scale(1.02);

	}

	100% {
		transform: scale(1.04);

	}
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg)
  }
  70% {
    transform: rotateX(20deg)
  }
  100% {
    transform: rotateX(0deg)
  }
}


@keyframes spinY {
  0% {
    transform: rotateY(-90deg)
  }
  70% {
    transform: rotateY(20deg)
  }
  100% {
    transform: rotateY(0deg)
  }
}

@keyframes collapseNav {
  0% {
      width: 100%
  }
  80% {
      width: 100px
  }
  100% {
      width: 120px
  }
}

@keyframes opacity-transition {
  0%   {opacity: 1}
  10%  {opacity: 1}
  20%  {opacity: 1}
  30%  {opacity: 1}
  40%  {opacity: 1}
  50% {opacity: 1}
  60% {opacity: 1}
  70% {opacity: 1}
  80% {opacity: 0.9}
  90% {opacity: 0.8}
  100% {opacity: 0.7}
}

@media (min-width:700px) and (max-width: 1149px){
  p{
    font-size: 0.9em;
  }
  h1{
    font-size: 1.17em;
  }
  h2{
    font-size: 1.05em;
  }
  h3{
    font-size: 0.90em;
  }
  h4{
    font-size: 0.9em;
  }
  h5{
    font-size: 0.9em;
  }
  h6{
    font-size: 0.9em;
  }
}
@media (min-width:1150px) and (max-width: 1300px){
  p{
    font-size: 1em;
  }
  h1{
    font-size: 1.5em;
  }
  h2{
    font-size: 1.5em;
  }
  h3{
    font-size: 1em;
  }
  h4{
    font-size: 0.8em;
  }
  h5{
    font-size: 0.75em;
  }
  h6{
    font-size: 0.7em;
  }

  .distance-badge{
    max-width: 90px;
    min-width: 130px;
    width: fit-content;
  }

  .recomm-toggle-container{
    max-width: 525px;
    width: 100%;
  }

  .reco-filter-text,
  .skills-count{
    font-size: 0.7rem;
  } 
  .reco-filter-button{
    min-width: 100px !important;
  }

  .skills-container{
    width: fit-content;
  }
}

/* @media (min-resolution: 1.5dppx){
  p{
    font-size: 0.75em !important;
  }
  h1{
    font-size: 1em !important;
  }
  h2{
    font-size: 0.95em !important;
  }
  h3{
    font-size: 0.9em !important;
  }
  h4{
    font-size: 0.85em !important;
  }
  h5{
    font-size: 0.85em !important;
  }
  h6{
    font-size: 0.85em !important;
  }
} */

@media(min-width: 1900px){
  h6.job-sub-title{
    font-size: 0.85em !important;
  }
}

