:root {
  --font-color: #0F1177;
  --secondary-font: #4f5f71;
  --background: #E4F2F6;
  --primary-color: #0F1177;
  --secondary-color: #0379A0;
}

.ql-container {
  border-radius: 0px 0px 15px 15px !important;
  margin-bottom: 25px;
  min-height: 150px;
}

.ql-toolbar {
  border-radius: 15px 15px 0px 0px !important;
}


.global-style{
  border-radius: 40px;
}

.scrollable-hidden{
  border-style: none solid none none;
  overflow: scroll;
  scroll-behavior: smooth;
}

.landing-page{
  width: inherit;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.dash-sm-logo{
  width: 50%;
  height: auto;
  min-width: 110px;
  max-width: 120px;
}

.dash-lg-logo{
  width: 80%;
  height: auto;
  max-height: 50px;
}

.full-width-center{
  width: 100%;
  text-align: center !important;
}

.popup {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 2px 2px 6px 1px #00000073;
}

.popup:hover {
  transform: scale(1.03);
  cursor: pointer;
}

.popup>p{
  font-size: 1.00rem;
}

/* Note: 
  All classes left in here to avoid styling multiple .css files for responsiveness.
  Open to however you want set css styling up.
*/

/* App.tsx*/
.parent-component{
  margin: 0.35rem;
  padding: 0.85rem;
  height: 96vh;
  flex-grow:1;
  border: none; 
  overflow: scroll;
  max-height: 1000px;
}

.site-loading{
  width: 99vw;
  height: 100vh;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--background);
  position: absolute;
  animation-duration: 2.5s;
  animation-name: opacity-transition;
  z-index: 10000;
}

/* NavigationComponent Styles */

.platform-logo {
  padding-top: 0.8em;
  font-size: 0.9em;
}

.platform-logo:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.skinnyStyles{
  animation: collapseNav 450ms ease-in forwards;

}

.chunkyStyles{
  animation: collapseNav 450ms ease-out reverse;
}

.platform-logo{
  margin-top: 1em;
  align-items: center;
}

.platform-logo > h1{
  font-family: 'Nunito';
  margin-left: 0.35em;
  font-weight: 700;
}

.active-nav {
  text-decoration: none;
  width: 50vw;
  border-radius: 16px;
  background-color: var( --primary-color);
  padding: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
}

.active-nav > div > p,
.active-nav > div > h3 {
  margin-left: 10px;
  color: white;
}

@media(max-width: 992px) {
  .active-nav {
    width: 60vw;
  }
}


.active-nav:hover,
.inactive-nav:hover{
  transform: scale(1.03);
  display: flex;
  justify-content: flex-start;
}

.active-nav .nav-text {
  color: #ffffff;
}

.inactive-nav > div > p {
  font-family: LexendDeca;
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.5;
  color: var(--font-color);
  margin-left: 10px;
}

.active-nav > div > p {
font-family: LexendDeca;
font-size: 1.125em;
font-weight: 600;
line-height: 1.5;
color: white;
}

.inactive-nav {
  width: 50vw;
  color: gray;
  text-decoration: none;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
}

@media (max-width: 900px){
  .active-nav, 
  .inactive-nav{
    padding: 0.7rem;
  }
}

.collapseMargins{
  margin: 0px !important;
}

.collapsed-c,
.collapsed-c:hover{
  justify-content: center;
}

.animate-rotate-y{
  animation: spinY 200ms ease-in forwards;
}

.animate-rotate-y-reverse{
  max-width: 40px;
  animation: spinY 200ms ease-in reverse;
  animation-iteration-count: 1;
}

.nav-logo-wrpr{
  width: 100%;
  justify-content: center;
  cursor: none;
}

.nav-txt-wrpr {
  flex-grow: 0.6;
  display: flex;
  align-items: center;
  cursor: 'pointer';
}

.nav-wrpr,
.nav-wrpr-bottom{
  width: 100%;
  height: 49.3vh;
}

.nav-wrpr-bottom{
  display: flex;
  align-items: flex-end;
}

.nav-bottom {
  width: inherit;
}



.nav-txt-wrpr > img{
  margin-right: 10px;
  max-width: 40px;
}
@media(min-resolution: 1.25dppx){
  .nav-txt-wrpr > img{
    max-width: 30px;
  }
}

.nav-txt-wrpr > p {
  margin-left: 10px;

}


/* DashboardComponent */
.dash-component{
  margin: 1rem;
  padding: 0.5rem;
}

.dash-welcome{
  margin: 0px;
}

.dash-main {
  width: 100%;
  height: calc(100% - 18vh);
  justify-content: space-between;
}

.dash-main-level-cards::-webkit-scrollbar {
  display: none;
}

.dash-top,
.dash-bottom{
  width: 100%;
  height: 66vh;
  justify-content: space-between;
}

.dash-top {
  height: 7.5rem;
}

.dash-col1,
.dash-col2{
  width: 50%;
  margin: 0px;
}

.dash-col1{
  min-height: 96%;
  height: 100%;
}

.dash-main-level-cards{
  height: 100%;
}

.dash-col2>.dash-main-level-cards:last-child{
  height: 100%;
  margin: -1px;
}

.dash-col2 {
  display: inline-grid;
  height: 100%;
  align-items: flex-start;
  justify-items: flex-end;
  grid-auto-rows: 1fr;
}

.dash-col2 > .dash-main-level-cards{
  height: 96%;
  float: right;
}


.dash-col2 > .dash-main-level-cards:last-child{
  align-items: flex-end;
}

.lower-widget-cont{
  min-width: 30%;
  min-height:15rem;
}


/* HeaderComponent */
.page-title{
  margin-top: -1em;
  margin-left: 1em;
}

.page-title>h1{
  font-size: 1.8rem;
  width: max-content;
  margin-top: -2em;
  margin-bottom: 1.2em;
}

.header-component{
  position: relative;
  width: 100%;
  min-height: 5vh;
  max-height: 5rem;
  align-items: center;
  justify-content: flex-end;
  padding-top: 0.5em;
  padding-bottom: 0.75em;
  user-select: none;
}

.profile-img-header{
  width: 55px;
  height: 55px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  animation: opacity-transition 2.5s ease-in-out 0s reverse;
}

.profile-img-header:hover{
  transform: scale(1.02);
  opacity: 0.85;
  box-shadow: 1px 1px 2px 2px #D9E1E7;
}


.header-wrpr{
  width: 100%;
  justify-content: space-between;
}

.header-right{
  height: fit-content;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}

.header-notification-icon{
  position: relative;
  cursor: pointer;
  z-index: 5;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 10px;
  margin: 0px 5px;
}

.header-notification-icon > img {
  border-radius: 16px;
  border: solid 2px var( --background);
  padding: 1em;
  background-color: white;
}

.header-notification-icon > img:hover,
.header-profile-icon > svg:hover {
  background-color: #F5F5F5;
}

.header-profile-icon {
  display: flex;
  width: 65px;
  z-index: 15;
}

.header-profile-icon > svg {
  font-size: 3.45rem;
  border: solid 2px var( --background);
  border-radius: 10px;
}

/* NotificationComponent */

.notification-card {
  padding: 10px 15px;
  width: 100%; 
  max-width: 440px;
  border-radius: 0%;
  border: 0px;
  border-bottom: 2px solid rgb(233, 233, 233);
  margin-left: auto;
  margin-right: auto;
}

.notification-card-unread{
  background: white;
  color: #444444;
}

.modal-overlay-wrapper{
  z-index: 15;
}

.notif-drop-down {
  min-width: fit-content;
  width: fit-content;
  max-width: 450px;
  max-height: 470px;
  background: white;
  margin-top: 10px;
  animation: rotateMenu 250ms ease-in;
  border-radius: 15px;
}

.notif-dropdown-title {
  padding: 20px 15px;
  font: "LexendDeca-SemiBold";
  font-size: 16px;
}

.notif-dropdown-header{
  min-height: 100px;
}

.notif-panel{
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

.notif-dropdown-unreadCount{
  font-size: 12px;
  color: #444444;
  font-weight: 600;
  cursor: default;
}

.notif-dropdown-markAllAsRead {
  font-size: 10px !important;
  font-weight: 700 !important;
}

.notif-highlight{
  background: var( --background) !important;
}

/* IMPORTANT: dynamic styling for clickable text in notification for redirecting users, do not delete. */
.notif-noun-link{
  text-decoration: underline;
  font-weight: 700;
  color: var( --font-color);
  cursor: pointer;
}

.notif-unread{
  background: transparent;
}

.notif-dropdown-earlier {
  padding: 15px;
  color: grey;
  font-size: small;
}

.notif-dropdown-notifs {
  max-height: 285px;
  overflow: scroll;
  z-index: 30;
}

.notif-dropdown-readMore {
  font-size: small;
  float: inline-end;
  position: absolute;
  top: -30px;
  left: 20px;
}

.notif-unread > div.p{
  color: blue;
}

.notif-dropdown-seeAll {
  padding: 0px 24px 15px 0px;
  float: right;
  font-size: small;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-line{
  width: 95%;
  border-bottom: 2px solid lightgray;
  margin-left: auto;
  margin-right: auto;
}

.dropdown-line2 {
  width: inherit;
  height: 15px;
  border-bottom: 2px solid rgb(233, 233, 233);
  position: absolute;
  right: 0px;
}

.notification-card-cont{
  min-width: 365px;
  min-height: 50px;
  width: inherit;
  right: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}


/* TopWidgetComponent */
.top-widget-metric > h1,
.top-widget-metric > h3{
  color: white;
}

.top-widget-count-wrapper{
  display: inline-flex;
  align-items: center;
}

.top-widget-icon{
  display: flex;
  justify-content: flex-end;
  height: fit-content;
}

.top-widget-icon >img{
  width: 100%;
}

/* EmployerTableComponent */
.employers-table-cont{
  height: 85%;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5em 1em 2em 1em;
  border-radius: 16px;
  border: solid 2px var( --background);
}

.custom-column-style{
  background: var( --background);
  cursor: pointer;
}

.status-column > div {
  padding: 0.3em 1em;
  border-radius: 7px;
  background-color: #a3eed6;
  text-transform: uppercase;
  color: #30d9a2;
  font-size: 0.9em;
}

.individual-status-column > span {
  padding: 0.5em 1em;
  border-radius: 7px;
  text-transform: uppercase;
  color: white;
  font-size: 0.65em;
  margin-left: auto;
  margin-right: auto;
  min-width: 120px;
  text-align: center;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

}

.employer-status-column > span {
  padding: 0.5em 1em;
  border-radius: 7px;
  text-transform: uppercase;
  color: white;
  font-size: 0.7em;
  font-weight: 600;
  min-width: 80px;
  text-align: center;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

}

.status-open{
  background-color: #3d7652;
}

.status-closed{
  background-color: #eb393d;
}

.skills-column > div {
  border-radius: 8px;
  background-color: #313131;
  padding: 0.3em 1em;
  font-weight: 600;
  line-height: 1.33;
  color: white;
}

.tags-column {
  white-space: normal;
  min-height: fit-content !important;
  display: inline-flex !important;
  align-items: center;
  overflow: hidden;
  padding: 5px !important;
}

.tags-column > div > p {
  border-radius: 8px;
  background-color: #313131;
  padding: 0.3em 1em;
  font-weight: 600;
  color: white;
  font-size: 0.7em;
  justify-self: baseline;
  margin: 2px 0px;
  width: fit-content;
}

.table-header{
  width: 100%;
  min-height: 40px;
  display: inline-flex;
}

.table-header > div {
  /* flex-grow: 1; */
  margin: auto;
}

/* .table-header > div > h2 {
  text-transform: uppercase;
  font-family: 'LexendDeca';
  font-weight: 600;
  font-size: 1.125em;
} */

.table-text > span {
  text-transform: capitalize;
  background-color:lightgrey;
  padding: 0.3em 0.6em;
  border-radius: 5px;
}

.site-access-badge{
  background-color: #d3d3d3cf;
  font-size: 0.75em;
  padding: 0.3em 0.6em;
  border-radius: 5px;
  text-transform: capitalize;
  font-family: 'LexendDeca';
  font-weight: 600;
  color: black;
  display: inline-flex;
  justify-content: space-between;
  width: fit-content;
}

.site-access-badge > p {
  margin-left: 5px;
  font-weight: 700;

}

.table-header > div > h2 > span > img {
  padding-right: 0.4em;
}

.table-header > button {
  font-family: 'Nunito';
  border-radius: 14px;
  margin-bottom: 5px;
}

.table-header > button > a.link-text { 
  text-transform: capitalize;
}

.table-first-column {
  background: var( --background);
  border-right: solid 1px var( --background)!important;
  white-space: normal!important;
  border-radius: 15px 0px 0px 15px;
  padding-left: 1.5em!important;
}

.employer-img {
  display: inline-flex;
  align-items: center;
}


#employer-img-text {
  margin: auto;
}

.description-overflow > div {
  overflow: hidden;
  display: contents;
}

/* EmployersCreateComponent */
.form-title{
  font-weight: 700;
  margin: 20px 10px 15px 10px;
  font-size: 1.1rem;
}

.form-half-width{
  width: 48%;
}
.form-third-width{
  width: 30%;
}

.form-qrtr-width{
  width: 22%
}

.multi-location{
  width: 50%;
  height:fit-content;
}

.individual-note-cont{
  padding: 10px;
  align-items: end;
}
.employer-note-cont{
  padding: 10px;
  align-items: inherit;
}

.disabled-note-props{
  display: block;
  text-align: center;
}

.employers-form,
.form-container{
  width: -webkit-fill-available;
  max-width: 1366px;
  height: 80vh;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  position: relative;
}

.form-container{
  padding: 0;
}

.emp-details {
  min-height: 100px;
}

.employer-logo-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  position: absolute;
  cursor: pointer;
}

.menuImgWrapper {
  width: 300px;
  height: 200px;
  position: relative;
  position: relative;
}

.ImgDisplay{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  border: 2px solid #bdbdbd;
  box-shadow: 1px 1px 2px 1px #6c5b5b1f;
  border-radius: 5px;
  background: var(--background);
}

.LoadedImgCont{
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.loaded-img{
  max-width: -webkit-fill-available;
  max-height: -webkit-fill-available;
  padding: 5px;
}

.no-img{
  width: 35px;
  margin-right: 5px;
  opacity: 0.7;
}

.remove-img-wrapper{
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 4;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 1px #0000002e;
  background: #8d9197;
  color: white;
  margin: 5px;
}

.remove-img-wrapper:hover{
  cursor: pointer;
  background: #ebebeb;
  box-shadow: 0px 1px 2px 1px #ffffff80;
  color:#e50101;
}


.emp-logo-container,
.contents {
  display: contents;
}

.img-feature-container {
  width: 100%;
  display: inline-flex;
  align-items: flex-end;
}

.prof-img-cont{
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}

.prof-img-cont > div{
  display: inline-flex;
  align-items: flex-end;
}

.form-inputs-container{
  border: 2px solid #D9E1E7;
  height: 73vh;
  max-height: 830px;
  border-radius: 10px 10px 0px 0px;
  padding: 0px 20px;
  overflow: scroll;
  scroll-behavior: smooth;
  position: relative;
}

/* .form-submit {
  width: inherit;
  height: 5vh;
  max-height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 10px 10px;
  border: 2px solid #D9E1E7;
  border-top: none;
  padding: 24px 10px;
} */

.form-submit>input{
  margin-right: 10px
}

.form-inputs-container > section {
  margin: 10px 0px;
}

.form-inputs-container::-webkit-scrollbar{
  width: 0px;
  background: transparent;
  height: 0px;
}

.cb-location{
  width: fit-content;
  height: 'fit-content !important';
  margin: 10px 0px;
}

.cb-location > label{
  padding-right: 9px;
}
/* SuccessComponent */

.success-overlay,
.waiting-overlay,
.inactive-overlay{
  position: absolute;
  height: 99vh;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index:100;
}

.inactive-overlay{
  background: transparent;
  height: 100vh;
}

.success-wrpr{
  text-align: center;
  height: 200px;
}

.inactive-wrpr{
  display: inline-flex;
  justify-content: center;
  width: 95%;
  height: 400px;
  align-items: center;
  display: inline-flex;
  box-shadow: 6px 3px 15px black;
  background: #f1f4fa;
  border-radius: 10px;
}

.success-text{
  margin-top: -140px;
  display: grid;
}

.inactive-text{
  margin-bottom: 20px;
  text-align: center;
}

.success-text>img{
  /* padding-bottom: 170px; */
}

.waiting-text{
  margin-top: -167px;
  text-align: center;
}

.success-text > button{
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.success-msg-wrpr{
  width: fit-content;
}

.success-msg-wrpr > img,
.success-msg-wrpr > button{
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

/* CompanyDetailsComponent */

.company-details-container{
  display: flex;
  align-items: center;
  width: max-content;
  min-width: 24.5vw;
  max-width: 45vw;
  height: 10vh;
  min-height: 100px;
  max-height: 125px;
  border: 2px solid var( --background);
  border-radius: 25px;
  padding: 10px;
  overflow: hidden;
}

.comp-detail-title-container{
  width: 80%;
}

.comp-detail-mn-title{
  overflow: hidden;
  height: fit-content;
  max-height: 6vh;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: clamp(0.5em, 1.5vw, 1.5em);
}

@media(max-width: 768px) {
  .comp-detail-mn-title {
    height: unset;
    max-height: unset;
    display: block;
    align-items: unset;
    font-size: unset;
  }
}

.mobile-view-header {
  text-align: center;
  margin-bottom: 20px;
}

.avatar-img {
  margin-right: 10px;
}

.avatar-img > div{
  width: 70px !important;
  height: 70px !important;
}

.indiv-status{
  padding: 0.3em 1em;
  align-items: center;
  margin-top: 5px;
}

.details-img-title {
  display: inline-flex;
  align-items: center;
}

.details-img-title > img{
  width: 70px;
  height: 70px;
  margin-left: 1.5em;
  margin-right: 1em;
  border-radius: 50%;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.details-img-title > h1{
  padding: 0px 5px;
}

.default-employer-img{
  border-radius: 0px !important;
  box-shadow: none !important;
}

.organization-details-card {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card-details-cont{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.organization-details-card > img {
  width: 6em;
  height: 6em;
  border-radius: 50px;
  margin-left: 1em;
}

.organization-details-card > h1 {
  width: 100%;
  margin-left: 10px;
  font-size: 1.8rem;
}

.company-details-container > h1 {
  margin-left: 5px;
}

h6.job-sub-title{
  color: var(--secondary-color);
  font-size: 0.8em;
}

/* EmployerComponent */
.employer-component
.employer-content, 
.recomm-content,
.individual-content,
.employer-details-nav,
.recommendations-banner {
  width: 100%;
  max-width: 1740px;
  max-height: 685px;
}
@media(min-width: 1200px){
  .employer-component
  .employer-content, 
  .recomm-content,
  .individual-content,
  .employer-details-nav,
  .recommendations-banner {
    width: 97%;
  }
}
@media(min-width: 1400px){
  .employer-component
  .employer-content, 
  .recomm-content,
  .individual-content,
  .employer-details-nav,
  .recommendations-banner {
    width: 98%;
  }
}

.employer-component,
.recommendation-component{
  display: inline-grid;
  grid-template-rows: 120px auto;
  grid-template-columns: 100%;
  width: 100%;
  height: 97%;
  margin-top: -4rem;
}

.recommendation-component{
  grid-template-rows: 120px 75vh;
  height: 90%;
}

.employer-details-nav{
  display: flex;
  justify-content: space-between;
  width: inherit;
  align-items: flex-end;
  height: fit-content;
  min-height: 110px;
  max-height: 140px;
  margin-top: 0.7em;
}

.employer-nav{
  height: fit-content;
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.employer-nav > div{
  margin-right: 5px;
}

.employer-content, 
.recomm-content{
  display: inline-flex;
  width: inherit;
  margin-top: 1rem;
  overflow: hidden;
}

.individual-content {
  display: inline-flex;
  width: inherit;
  height: 96%;
  max-height: 640px;
  margin-top: 1em;
  overflow: hidden;
}

.listing,
.listing-empty{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var( --background);
  border: 1px solid var( --background);
  margin: 2px 0px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 1px 1px #d9dce3;
}

.listing-empty{
  justify-content: center;
}

.listing-empty>p{
  font-style: italic;
  font-family: 'LexendDeca';
}

.listing-info{
  font-family: 'LexendDeca';
  font-weight: 500;
  display: flex;
  align-items: center;
}

@media(max-width: 768px) {
  .listing-info {
    display: block;
    font-size: 14px;
    align-items: unset;

  }

  .active-badge {
    display: block;
    width: 70px;
    margin-top: 5px;
    margin-left: unset!important;
  }
  
}

.active-badge{
  text-align: center;
  min-width: 50px;
  border-radius: 3px;
  color: white;
  font-size: 0.7em;
  font-weight: 500;
  padding: 3px 6px;
  margin-left: 10px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.open-badge{
  background: var(--primary-color);
}

.closed-badge{
  background: #e50101;
}

.recomm-content{
  max-height: 785px;
}

.employer-column1, 
.recomm-column1{
  width: 30%;
  min-width: 300px;
  height: 100%;
  border: 2px solid var( --background);
  border-style: none solid none none;
  overflow: scroll;
  scroll-behavior: smooth;
}

.employer-column1 {
  padding: 15px;
}

.recomm-column1 {
  padding: 5px;
}


.recomm-column1{
  width: 55%
}

.recomm-column2{
  width: 45%;
  height: inherit;
  display: grid;
  grid-template-rows: 15% 68% 17%;
  grid-template-columns: 100%;
  padding: 0px;
  border: 2px solid var( --background);
  border-style: none solid none none;
  overflow: hidden !important;
}

.recomm-column2-details{
  overflow: scroll;
  scroll-behavior: smooth;
  height: 70%;
  min-height: 330px;
  padding: 20px;
}

.employer-column2{
  width: 70%;
  overflow: auto;
  scroll-behavior: smooth;
  padding: 1.5em;
}

.employer-column2::-webkit-scrollbar {
  width: 6px;
  height: 0;
}

.employer-column2::-webkit-scrollbar-track {
  background-color: #d3d9de;
}

.employer-column2::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color);
  border-radius: 2.5px;
}
  
.employer-column1::-webkit-scrollbar,
.recomm-column1::-webkit-scrollbar,
.scrollable-hidden::-webkit-scrollbar,
.notif-drop-down::-webkit-scrollbar,
.parent-component::-webkit-scrollbar{
  width: 3px;
  background: transparent;
  height: 0px;
}

.hide-scroll::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
  height: 50%;
}

.hide-scroll::-webkit-scrollbar{
  width: 3px;
  background: #0000ff1f;
  height: 0px;
}



.emp-support-lead {
  border: 2px solid var( --background);
  border-style: none none solid none;
  padding: 0.2em 0.2em 0.7em 0.7em;
}

@media (max-width: 768px) {
  .emp-support-lead {
    border: 1.5px solid #d9e1e7;
    border-radius: 16px;
    max-width: 60vw;
  }


}

.emp-overview{
  display: table;
  padding: 0.2em 0.2em 0.7em 0.7em;
}

.emp-overview-container{
  min-height: 55px;
}

.provider-details{
  margin-left: 5px;
}

.employer-reco-info{
  min-height: 90px;
  max-height: 150px;
  width: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}


/* JobCreateComponent */

.select-provider {
  height: fit-content;
  margin: 15px 0px;
}

.form-input-cont{
  display: grid;
  grid-template-rows: min-content;
  min-height: 100px;
  margin-bottom: 10px;
}

.form-switch{
  margin: 10px 0px;
}

.form-switch > h5 {
  font-family: 'LexendDeca';
  color: rgba(0, 0, 0, 0.6);
}

.emp-switch{
  position: relative;
}

.emp-switch > h5 {
  position: absolute;
  top: -8px;
  left: 15px;
}

.ind-switch {
  position: relative;
}

.ind-switch > h5 {
  position: absolute;
  top: -25px;  
}

.job-switch > h5 {
 margin-bottom: 0.25em;
}

.active-switch{
  color: black;
  font-weight: 600;
}

.inactive-switch{
  color: gray;
  font-weight: 400;
}

.check-box-multiline{
  display: grid;
  margin: 10px 0px;
}

/* IndividualAttachmentsDownload */
.align-dl-icon-right {
  display: inline-flex;
  max-width: 325px;
  margin: 0.20rem 0;
  margin-bottom: 1.15em;
}

.align-dl-icon-right > p {
  font-size: 0.80em;
  color: #4f5f71;
  font-weight: 600;
}

.align-dl-icon-right > a > img {
  height: 1.15em;
}

.stack-vertically{
  display: inline-block;
  max-width: 325px;
}

.stack-vertically > div:first-child{
  margin-top: 0.5rem;
}

.child-spacing > * {
  display: inline-flex;
  align-items: center;
  min-height: 1.5rem;
  height: fit-content;
  max-height: 3rem;
  margin: 0.25rem 0.25rem;
}

.check-box-cont{
  margin: 10px;
}

.check-box-cont >h6{
  margin: 10px;
}

.helper-text{
  margin-left: 1em;
}
.ref-helper-text{
  margin-top: -10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.form-input-cont > h6 {
  margin-left: 10px;
}

.form-icon > img {
  width: 24px;
  height: 28.8px;
}

.new-formm-section{
  margin-top: 2em;
}

.form-container{
  width: inherit;
}

.horizontal-scroller{
  width: max-content;
  display: inline-flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
}

.panel{
  padding: 0 25px;
}
/* IndividualComponent */

.ind-toggle-container,
.recomm-toggle-container{
  height: fit-content;
  margin: 10px 0px;
  padding: 3px;
  background: var( --background);
  border-radius: 20px;
  box-shadow: 1px 1px 4px 2px #0000001a;
  overflow: hidden;
}


.recomm-toggle-container{
  padding: 4px; 
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

.recomm-toggle-container > h6{
  font-weight: 500;
}

/* IndividualCreate Component */
.go-back-form-style{
  display: flex;
  justify-content: flex-end;
}

.go-back-form-style > button{
  padding: 0.2rem;
} 

.blue-stitch{
  background: var( --background);
  padding: 10px;
  border-radius: 15px;
  border: 2px dashed #cacaca;
  min-height: 75px;
}

.grey-stitch{
  padding: 10px;
  min-height: 75px;
}

.blue-stitch-min-width {
  min-width: 350px;
}

.toggle-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  height: 50px;
}

.shift-right{
  margin-left: 25px;
}

.meat-balls{
  display: flex;
  justify-content: flex-end;
}

.file-upload{
  justify-content: space-between;
  width: 50%;
  min-width: 300px;
  max-width: 350px;
}

/* RecommendationsCard */

.recomm-desc-cont{
  display: inline-grid;
  width: min-content;
  flex-grow: 1;
}

.metric-bar-outer{
  width: 80%;
  height: 10px;
  min-width: 200px;
  max-width: 250px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto; 
}

.recomm-metric-cont{
  display: flex;
  justify-content: center;
  width: min-content;
  min-width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  
}
.recomm-metric-cont > span{
  margin-top: 2.3px;
}

.recomm-card{
  align-items: center;
  height: 50px;
  border-radius: 10px;
  margin: 2px 0;
  padding: 3em 1em;
  border: 2px solid var(--background);
}

.recomm-card:hover,
.recomm-highlight{
  cursor: pointer;
  background: var(--background);
  
}

.recomm-card:active{
  background:var(--background);
}

.recomm-col2-details{
  height: 100%;
  min-height: 330px;
  overflow: scroll;
  padding: 10px;
  scroll-behavior: smooth;
  border-bottom: 2px solid #bdbdbd;
  padding-bottom: 100px;
}

.reco-keyword-detail{
  display: flex;
  justify-content: flex-start;
}

.reco-metrics{
  width: 100%;
  display: contents;
}


.reco-title-wrapper{
  display: inline-flex;
  overflow: hidden;
  margin-left: 5px;
  width: 95%;
}

.reco-tags-title{
  display: inline-flex;
  position: relative; /* positioned relative to its normal position */
  cursor: none;
  white-space: nowrap;
  width: 100%;
  top: 0; /* default */
  right: 0; /* default */
}

.reco-tags-title:hover{
  animation-duration: 3s;
  animation-name: sideScroll;
  animation-iteration-count: infinite;
}

.reco-header{
  padding: 0px 10px;
}

@keyframes sideScroll {
  from{
    right: 0;
  }

  to{
    right: 70%;
  }
}

.no-highlight{
  color: black !important;

}

.mid-highlight{
  background: #0082bbed !important;
}

.skills-container{
  display: grid;
  height: 30px;
  overflow: hidden;
  margin: 0px 3px;
}

.animate-badge{
  transition: height 1s ease-in-out;
  width: 110px;
}

.animate-badge:hover{
  min-height: 30px;
  height: max-content;
}

.skills-container:hover{
  height: max-content;
  cursor: pointer;
}

.reco-table{
  padding: 0px 10px;
}

/* RecommendationFilterOptions */


/* EmployerSupportLeadDetails */

.details-cover{
  background: var( --background);
  /* background: linear-gradient(126deg, rgb(115 160 249) 0%, rgb(241 244 250) 73%, rgb(131 145 177) 100%); */
  border-radius: 5px 15px 0px 0px;
  box-shadow: 1px 1px 6px 3px #bdbdbdba;
  height: 84% !important;
  overflow: hidden!important;
}


.details-provider-cover{
  background: var( --background);
  /* background: linear-gradient(16deg,  rgb(115 160 249) 0%, rgb(241 244 250) 73%, rgb(131 145 177) 100%); */
  height: 150%;
  width: 100%;
  border-radius: 0px 0px 15px 15px;
  padding-bottom: 100%;
}

.es-img-info{
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.es-img-info > img{
  width: 50px;
  height: 50px;
  border-radius: 30px;
  margin-right: 15px;
}

/* RecommendationShowTagMatches */

.matches-widget{
  min-height: 85px;
  border-bottom: 2px solid #bdbdbd;
  background: var(--background);
  z-index: 1;
}

.es-details-cont{
  height: 100%;
  min-height: 70px;
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  background: var(--background);
}

.es-details-cont > div{
  height: fit-content;
}

.es-details-cont > div >  h6 {
  padding: 5px;
}
/* RecommendationsWidgetComponent */
p.recommendations-selector{
  font-size: 0.8rem;
}

/* InvitesComponent */

.invite-card{
  width: 500px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.invite-msg{
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.invite-msg > img {
  width: 120px;
}

.invite-msg > p {
  font-family: 'LexendDeca';
  color: var(--primary-color);
  font-weight: 700;
}




.profile-form{
  margin-top: 50px;
}

@media (min-resolution: 1.50dppx) {
  .profile-form{
    margin-top: 10px;
  }

  .blue-text{
    font-size: max(1.5vw, 30px);
  }
}

/* .invite-name-fields,
.profile-name-fields{
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
} */

.invite-email-field,
.profile-phone-field{
  width: 100%;
}



.field-error{
  min-height: 80px;
}
/* Profile Component */
.settings-header{
  
}

.empty-profile-img-backdrop,
.not-empty-profile-img-backdrop{
  width: 150px;
  height: 150px;
  z-index: 0;
  border-radius: 50%;
  background: #d6d9d9;
  position: absolute;
  top: 0;
}

.empty-profile-img-backdrop{
  border: 2px dashed #8f8989;
}

.profile-img-cont{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 2px;
}

.profile-img-cont>img.loaded-img{
  border-radius: 50%;
}

.profile-logo-input{
  width: 100px;
  height: 100px;
  border-radius: 20px;
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 25px;
  left: 25px;
}

@media(max-width: 992px){
  .profile-logo-input{
    width: 75px;
    height: 75px;
    top: 0px;
    left: 0px;
  }
}

.profile-modal{
  border-radius: 10px;
  padding: 10px 0px;
  width: 120px;
  margin-top: 10px;
}

.profile-img-wrapper{
  width: 130px;
  height: 160px;
  position: relative;
}

.landing-header-title > img {
  max-width: 520px;
  min-width: 280px;
  width: 22vw;
}

.landing-page-component{
  width: 70%;
  height: 100%;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-template-rows: 20% 60% 20%;
  margin-top: -8%;
}


.landing-upper-content,
.landing-lower-content{
  width: 100%;
  height: 10%;
  display: inline-flex;
  justify-content: center;
}

.landing-lower-content{
  width: 100vw;
  display: inline-block;
  position: absolute;
  bottom: 0px;
}

.lower-texts{
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

.lower-texts > p {
  font-family: 'LexendDeca';
  color: #425466;
}

#copyright-title {
  font-weight: 600;
}

.lower-buttons > button {
  margin: 0px 10px;
}

.landing-main-content{
  display: inline-flex;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding: 30px 60px;
  border: 1px solid #0379a036;
  border-radius: 16px;
  box-shadow: 1px 1px 2px 3px #0379a045;
  margin-left: 20px;
  margin-right: auto;
}

.landing-content-left{
  width: 50%;
}

.landing-header-title{
  font-size: 3em;
}

.landing-content-left > p {
  max-width: 525px;
  width: max-content;
  margin-top: 10px;
  line-height: 1.38;
  color: #425466;
  font-family: 'Nunito';
  margin-left: auto;
  margin-right: auto;
}

.landing-content-left{
  min-width: 525px;
}

.landing-content-left > h1 {
  font-size: 2.35rem;
  font-family: 'LexendDeca';
  font-weight: bold;
  line-height: 1.5;
}

.blue-text{
  color: var( --font-color);
  width: fit-content;
  font-size: max(2vw, 36px);
}

.middle-text{
  font-size: 1.5vw;
}

.landing-content-left > h3 {
  font-size: 2.15rem;
  font-family: 'LexendDeca';
  font-weight: bold;
  line-height: 1.5;
}


.landing-login-wrapper{
  display: inline-flex;
  width: 90%;
  margin-top: 20px;
  justify-content: space-around;
}

.landing-login-wrapper > a{
  margin-left: unset;
}

.landing-login-wrapper > button {
  min-width: 150px;
}

.hr-divider{
  border: 1px solid #e3e4e5;
  width: inherit;
}

.landing-title {
  text-align: center;
  margin-bottom: 1em;
}


.no-jobs-msg{
  height: 100%;
}

.no-jobs-msg >p {
  text-align: center;
  margin-top: 40px;
  font-size: 0.85rem;
}

.hide{
  display:none;
}

.distribution-select > div.MuiOutlinedInput-root > fieldset > legend {
  width: fit-content;
}

/* Reporting Component */

.reporting-table-dimensions{
  width: 100%;
  margin-top: 10px;
}

@media(min-height: 865px) {
  .reporting-table-dimensions{
    height: 515px;
  }
}
@media(min-height: 900px) {
  .reporting-table-dimensions{
    height: 615px;
  }
}

.hidden-pie-column{
  display: none;
}

.show-top{
  align-items: flex-start;
}

.show-bottom{
  align-items: flex-end;
}

.show-top,
.show-bottom{
  height: min-content;
}

.ind-status-pie{
  max-width: 450px;
  width: 100%;
}

.ind-pie-table-wrpr{
  height: 450px;
  width: 70%;
}

@media(max-height: 768px) {
  .ql-toolbar.ql-snow .ql-formats { 
    margin-right: 0px!important;
  }
}

@media(min-resolution: 1.25dppx){
  .employers-table-cont{
    max-height: 880px;
  }
}
@media(min-resolution: 1dppx) and (max-resolution: 1.20dppx){
  .employers-table-cont{
    max-height: 875px;
    min-height: 560px;
    overflow: hidden;
  }
}

@media (min-height: 600px) {
  .dash-main{
    height: calc(100% - 30vh);
  }
  .dash-top-level-cards {
    height: 94px!important;
  }

  /* div.dash-top-level-cards > div > h1 {
    font-size: 0.9em!important;

  }

  div.dash-top-level-cards > div > h3 {
    font-size: 1.75em!important;
  } */

  div.dash-top-level-cards > div > img {
    width: 54px!important;
    height: 54px!important;
  }

  .dash-top {
     height: 6.75rem!important;
  }
}

@media(min-resolution: 1.25dppx){
  div.dash-top-level-cards > div > img {
    width: 44px!important;
    height: 44px!important;
  }
}


@media (min-height: 700px) and (max-height: 1029px) {
  div.dash-main {
    height: calc(100% - 26vh);
  }

  div.individual-content {
    max-height: 51vh;  
  }

  @media (min-height: 750px){
    div.dash-main {
      height: calc(100% - 26vh);
    }
  }

  
@media (min-height: 759px) {
  
  .recomm-col2-details{
    height:100%;
  }

  div.dash-main{
    height: 70%;
  }
}

  @media (min-height: 802px){
    div.dash-main {
      height: calc(100% - 22vh);
    }
    div.individual-content {
      max-height: 55vh;  
    }
  }
  @media (min-height: 900px){
    div.dash-main {
      height: calc(100% - 21vh);
    }

    div.individual-content {
      max-height: 57vh;
    }
  }
  
  @media(min-height: 901px){
    div.individual-content {
      max-height: 62vh;
    }
  }

  div.employer-reco-info {
    min-height: 90px;
    width: 26vw;
  }

  .employer-reco-info > h4{
    font-size: 0.8em;
  }
}


@media (max-height: 785px) {
  .dash-col1 > .dash-main-level-cards{
    height: 100%;
  }
  .dash-col2 > .dash-main-level-cards{
    height: 93%;
  }
  .reporting-table-job{
    height: 430px;
  }
  .reporting-table-ind{
    height: 450px;
  }
  .ind-pie-table-wrpr{
    height: 440px;
    width: 70%;
  }
}
@media (max-height: 680px) {
  .reporting-table-job,
  .reporting-table-ind{
    height: 380px;
  }

  .ind-pie-table-wrpr{
    height: 396px;
  }

  @media (min-resolution: 1.25dppx) {
    .ind-pie-table-wrpr,
    .reporting-table-job,
    .reporting-table-ind{
      height: 345px;
    }
  }
}

@media (min-height: 1030px) {
  .dash-main {
    height: 79vh;
  }

  .employer-component {
    height: 100%;
    max-height: 1030px;
    margin-top: -4rem;
    align-content: center;
  }
}

@media (max-resolution: 1.13dppx) and (min-height: 1030px) {
  .dash-col1, .dash-col2 {
    height: 96%;
  }
}


@media (min-resolution: 1.25dppx) {
  .dash-main{
    height: 70%;
  }

  .job-type-badge,
  .status-badge{
    padding: 0.3em 1em;
    font-size: 0.65em;
    height: fit-content;
  }
  h6.job-sub-title{
    font-size: 0.8em;
  }
}

@media (min-resolution: 1.38dppx) and (max-height: 692px) {
  .landing-page-component {
    padding-left: 100px;
    padding-right: 100px;
  }
}

/* Mobile styles 320-480px for Landing page  */

@media (min-width: 320px) and (max-width: 480px) {
  body {
    max-width: 480px;
    min-width: 320px;
  }

  .landing-title > h3 {
    font-size: 0.9em;
    padding-left: 1.7em;
    padding-right: 1.7em;
  }


  .landing-page-component {
    display: inline-grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    grid-template-rows: 20vh 60vh 20vh;
  }

  .landing-page {
    height: 100%;
  }
  
  .landing-content-left {
    min-width: unset;
    width: unset;
    margin: 5px
  }

  .landing-header-title {
    text-align: center;
  }

  .landing-header-title > img {
    width: 200px;
  }

  .landing-content-left > p {
    max-width: 480px;
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 1.7em;
    padding-right: 1.7em;
    margin-bottom: 0;
    line-height: 0;
  }

  .landing-content-left > h1 {
    text-align: center;
    font-size: 1.25rem;
  }

  .landing-content-left > h3 {
    text-align: center;
    font-size: 1.25rem;
  }
  
  .blue-text{
    font-size: max(2vw, 25px) !important;
  }

  .landing-login-wrapper {
    max-width: unset;
    width: unset;
  }

  .landing-login-wrapper > button {
    float: unset;
    margin-top: unset;
  }

  .lower-texts {
    display: block;
    justify-content: unset;
    text-align: center;
  }

  #root {
    max-width: 480px;
  }

  .landing-upper-content, .landing-lower-content {
    height: unset;
  }

  .landing-upper-content {
    margin-top: 1em;
  }

  .landing-main-content {
    height: unset;
    display: block;
    padding: 0px;
    margin: unset;
  }

  .app-container {
    display: block;
    height: inherit;
  }

  .landing-content-left {
    width: unset;
  }

  .landing-login-wrapper{
    display: inline-grid;
    margin: 40px;
    row-gap: 1em;
  }
}

@media (min-width: 480px) and (max-width: 999px){
  .landing-page-component {
    display: inline-grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    grid-template-rows: 20% 60% 20%;
  }

}

@media (min-height: 938px) {
  .landing-content-left > h3 {
    font-size: 3.15rem; 
  }

  .landing-content-left > h1 {
    font-size: 3.35rem;
  }

  .landing-title > h3 {
    font-size: 1.5em;
  }
}

@media (min-width: 992px) {
  div.section-header > h1 {
    margin-left: 0.5em;
  }
}